// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}

// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight>pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;

  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json>pre {
  background: none #fff !important;

}

.example-code>.json {
  background: none #fff !important;
}

.symbol.symbol-45>svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.display-block {
  display: block;
}




.btnhoverani {
  animation: mymove 2s infinite;
}

@keyframes mymove {
  from {
    box-shadow: 0px 0px 0px 5px #0006
  }

  to {
    box-shadow: 0px 0px 0px 20px #0000
  }
}

.uppy-StatusBar-actionBtn--done {
  animation: mymove 2s infinite;
}


.btnthemecolor {
  background-color: #9C27B0 !important;
}


.card.card-custom>.card-header .card-title .card-label.headd {
  font-size: 1.1em;
}

.font-size-sm.smlhed {
  font-size: 15px !important;
}

.guidedspecifications .mt-3 {
  font-size: 1.4em;
  font-weight: 500
}

.guidedspecifications .font-size-sm.font-weight-bolder.text-white.pl-10.pr-11 {
  padding-top: 10px;
  padding-bottom: 10px;
  background: rgba(0, 0, 0, .4) !important;
  font-weight: 400 !important;
}

.guidedspecifications .btnthemecolor {
  background: #3699FF !important
}



// sample images text over images
.sample_img {
  position: relative;
  width: inherit;
}

.sample_img:before {
  content: "Sample Image";
  position: absolute;
  left: 0;
  top: 0;
  background: #0004;
  width: 100%;
  height: 100%;
  font-size: 150%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;

}


.newClass {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: rgb(226, 227, 228);
}



.genericHeading {
  // font-size: 15px;
  font-size: 15px !important;

  opacity: 70%
}


.card.card-custom>.card-footer.paddleft {
  padding: 32px 4px 0px
}

.custom {
  padding: 5px 3px;
}

.checkbox>p.morespace {
  padding: 0px 16px 0px 26px;
  margin: 0px
}