h1, h2, h3, h4, h5, h6{margin:0px; padding:0px}
p{margin:0px; padding:0px}
ul {margin:0px; padding:0px; list-style:none}
ul li{margin:0px; padding:0px}
a{text-decoration:none; transition:all 0.5s ease-in-out; transition:all 0.5s ease-in-out;
-moz-transition:all 0.5s ease-in-out; -ms-transition:all 0.5s ease-in-out; -o-transition:all 0.5s ease-in-out; -webkit-transition:all 0.5s ease-in-out;}
.navbar{padding:0px 0px;}
.btn{text-decoration:none; transition:all 0.5s ease-in-out; transition:all 0.5s ease-in-out; -moz-transition:all 0.5s ease-in-out; -ms-transition:all 0.5s ease-in-out; -o-transition:all 0.5s ease-in-out; -webkit-transition:all 0.5s ease-in-out;}



/***poup-02*****/
.socil_congrs{padding:40px 0px; text-align:center}

.socil_congrs .modal-header{padding:0px; border:none}
.socil_congrs .modal-header .close{margin:0px; padding:10px 15px; right:0px; position:absolute; z-index:999}
.socil_congrs .modal-dialog{border-radius:10px; max-width:480px;}
.socil_congrs .modal-content{border-radius:10px; border:none}
.congrs h2{font-size:30px; font-weight:600; text-align:center; color:#404040}
.congrs p{font-size:16px; font-weight:400; text-align:center; color:#404040; padding:10px 0px;}
.congrs{padding:20px 0px;}
.congrs ul li a{background:#fff; border:2px solid #1050bf; color:#1050bf; font-size:20px; height:45px; width:45px; text-align:center; padding:6px 0px; text-decoration:none; display:block; border-radius:100px;}
.congrs ul li a:hover{background:#1050bf; color:#fff;}
.congrs ul li{display:inline-block; padding:5px 10px;}
.socil_congrs .btn{font-size:16px; font-weight:400; color:#fff; padding:15px 0px; background:#1050bf; border-radius:50px; text-align:center; text-decoration:none; width:210px; display:block; margin:10px auto;}


/***Four-box-css*****/
.inger_box{background:#f2f2f2; padding:20px; border-radius:4px; margin:20px; text-align:center}
.inger_box p{padding:5px 0px; color:#424354; font-size:14px;}
.inger_box h2{padding:10px 0px; color:#424354; font-size:27px; font-weight:600;}
.inger_box a.next{font-size:17px; font-weight:400; color:#fff; padding:10px 0px; background:#0072b8; border-radius:10px; text-align:center; text-decoration:none; width:180px; display:block; margin:20px auto;}
.inger_box a.back{font-size:17px; font-weight:400; color:#fff; padding:10px 0px; background:#949599; border-radius:10px; text-align:center; text-decoration:none; width:180px; display:block; margin:20px auto;}
.inger_box a.demo{font-size:17px; font-weight:400; color:#fff; padding:10px 0px; background:#0072b8; border-radius:10px; text-align:center; text-decoration:none; width:210px; display:block; margin:20px auto;}
.inger_box a.start{font-size:17px; font-weight:400; color:#fff; padding:10px 0px; background:#8f3f98; border-radius:10px; text-align:center; text-decoration:none; width:180px; display:block; margin:20px auto;}
.destho{background:#1f1e2c; padding:20px 10px; margin:20px auto; width:400px;}
.destho ul li{padding:6px 10px; color:#494c75; font-size:18px; text-align:left}
.destho ul li span img{max-width:48px; padding-right:15px;}
.destho ul li.twbo{border:3px solid #106cad; padding:5px 5px; margin:5px 0px;}
.destho ul li.twbo a{padding:10px 0px; color:#fff;}
.destho ul li a{color:#a5a4b4; font-size:17px; text-decoration:none; display:block}
.destho ul li a:hover{color:#fff;}
.destho ul li a .fa{padding-right:7px;}
.destho ul li.grde{padding:15px 0px;}
.butondd{display:flex;}

.modal-body{padding:0px !important}
.inger_box{border-radius:4px}
.modal.show .modal-dialog{max-width:505px;}