@charset "utf-8";
/* CSS Document */




@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');




html, body{font-family: 'Roboto', sans-serif; height:100%; }
h1, h2, h3, h4, h5, h6{margin:0px; padding:0px}
p{margin:0px; padding:0px}
ul {margin:0px; padding:0px; list-style:none}
ul li{margin:0px; padding:0px}
a{text-decoration:none; transition:all 0.5s ease-in-out; transition:all 0.5s ease-in-out;
-moz-transition:all 0.5s ease-in-out; -ms-transition:all 0.5s ease-in-out; -o-transition:all 0.5s ease-in-out; -webkit-transition:all 0.5s ease-in-out;}
.navbar{padding:0px 0px;}
.btn{text-decoration:none; transition:all 0.5s ease-in-out; transition:all 0.5s ease-in-out; -moz-transition:all 0.5s ease-in-out; -ms-transition:all 0.5s ease-in-out; -o-transition:all 0.5s ease-in-out; -webkit-transition:all 0.5s ease-in-out;}
.navbar{padding:0px 0px;}
.navbar-light .navbar-toggler{border-radius:0px; border-color:#1f4e78; outline:none; box-shadow:none; line-height:inherit; padding:2px 10px; color:#1f4e78}
.navbar-light .navbar-toggler-icon{background:none;}
.navbar-toggler-icon{height:auto; width:auto;}

/* top_menu */
.top_menu{background:#EEF0F8}
.top_menu .navbar-light .navbar-nav .nav-link{font-size:15px; font-weight:400; padding:9px 20px; border-radius:3px; border:1px solid #1E1E2D; color:#1E1E2D}
.top_menu .navbar-light .navbar-nav .nav-item{padding:4px 7px}
.top_menu .navbar-light .navbar-nav .nav-link:focus, .top_menu .navbar-light .navbar-nav .nav-link:hover{background:#1E1E2D; color:#fff;}
.top_menu .navbar-light .navbar-brand{max-width:160px;}
/* End-top_menu */

/* top_banner */
#top_banner{background:#EEF0F8; padding:30px 0px;}
#top_banner h1{font-size:52px; color:#000; font-weight:700;}
#top_banner p{font-size:17px; color:#000; font-weight:300; padding:12px 0px;}
#top_banner a{font-size:18px; color:#000; font-weight:300; padding:10px 0px; border-radius:3px; border:1px solid #1E1E2D; background:#1E1E2D; width:380px; text-align:center; display:block; color:#fff; text-decoration:none; }
#top_banner a:hover{background:#000}
#top_banner h4{font-size:14px; color:#000; font-weight:300; padding:10px 15px;}
.vodeli video{width:100%; height:290px}
.vodeli{padding:0px 0px 0px 50px; text-align:center}
#top_banner h5{padding:10px 0px}
#top_banner h5 .fa{color:#FFC107; text-shadow:0px 0px 1px #000; font-size:16px;}
#top_banner h3{font-size:16px; color:#000; font-weight:300; padding:0px 0px; font-style:italic}
.trusted{text-align:center; padding:30px 0px 10px; line-height:1.5em}
#top_banner h6{font-size:18px; color:#000; font-weight:300; padding:10px 0px;}
#top_banner h5 img{margin:0px 1px}
/* End-top_banner */

/* retouching_section */
#retouching_section{background:#1E1E2D; padding:30px 0px; text-align:center}
.head {text-align:center}
.head h1{font-size:38px; color:#fff; font-weight:100; margin:0px;}
.head p{font-size:16px; color:#fff; font-weight:100; padding:5px 0px;}
#retouching_section .owl-theme .owl-nav{display:none;}
#retouching_section .owl-theme .owl-dots{display:none}
.bimg{padding:10px;}
.timg{padding:10px;}
#retouching_section .owl-item .bimg img{border:4px solid #1E1E2D; border-radius:10px; cursor:pointer}
#retouching_section .owl-item.active.current .bimg img{border:4px solid #ffc107; border-radius:10px;}
.main_videosi{position:relative}
.without{position:absolute; left:-140px; top:50px;}
.with{position:absolute; right:-120px; top:170px;}
.main_videosi{width:740px; margin:20px auto}
.butonn_tenphoto{text-align:center;}
.butonn_tenphoto a{font-size:18px; color:#1E1E2D; font-weight:300; padding:10px 0px; border-radius:3px; background:#fff; width:330px; text-align:center;margin:5px auto; display:block;  text-decoration:none; border:1px solid #1E1E2D}
.butonn_tenphoto a:hover{background:#EEF0F8; color:#1E1E2D}
.butonn_tenphoto h4{font-size:14px; color:#fff; font-weight:300; padding:10px 0px;}
/* End-retouching_section */


/* retouching_services */
#retouching_services{background:#EEF0F8; padding:30px 0px;}
.graycolor h1{color:#1E1E2D}
.graycolor p{color:#1E1E2D}
.gray h4{color:#000}
.gray a{border:1px solid #1E1E2D; background:#1E1E2D; color:#fff}
.gray a:hover{background:#000; color:#fff;}
.be_afbox{text-align:center; padding:10px 20px;}
.be_afbox p{font-size:20px; color:#1E1E2D; padding:15px 0px}
.be_afbox img{width:100%}
.editafter{margin:0px auto; width:95%}
.four_box {padding:5px 0px;}
.four_box p{font-size:17px; color:#1E1E2D; font-weight:400; text-align:center; padding:7px 0px;}
.four_box img{width:100%;}
.addmore{text-align:center; padding:10px 0px 20px;}
.addmore p{font-size:16px; color:#1E1E2D; font-weight:400; padding:10px 0px;}
.addmore a{border:1px solid #1E1E2D; color:#1E1E2D; padding:6px 29px; border-radius:3px; text-decoration:none; display:inline-block; margin:5px; font-size:16px;}
.addmore ul li{display:inline-block;}
/* End-retouching_services */

/* achievements_section */
#achievements_section{background:#1E1E2D; padding:30px 0px;}
.achie_box{border:0.8px solid #FFFFFF; border-radius:8px; text-align:center; padding:25px 15px; margin:15px 0px;}
.achie_box h3{font-size:40px; color:#fff;}
.achie_box h1{font-size:60px; color:#fff; font-weight:800; padding:5px 0px;}
.achie_box p{font-size:18px; color:#fff; font-weight:300; padding:0px 0px;}
/* End-achievements_section */

/* reviews_section */
#reviews_section{background:#EEF0F8; padding:30px 0px;}
.clint_rew{background:#1E1E2D; border:1px solid rgba(0, 0, 0, 0.1); border-radius:8px; padding:10px 30px; position:relative}
.clint_rew p{font-size:18px; font-weight:300; font-style:italic; padding:10px 15px; color:#fff;}
.firstd{position:absolute; left:10px; top:10px;}
.firstd1{position:absolute; right:10px; bottom:10px;}
.slider_box{width:80%; margin:20px auto; }
.slider_box h5{padding:10px 0px; text-align:center}
.slider_box h5 .fa{color:#FFC107; text-shadow:0px 0px 1px #000; font-size:16px;}
.slider_box h3{font-size:18px; font-weight:400; color:#000;  text-align:center}
.owl-theme .owl-nav{display:none}
/* End-reviews_section */


/* retouching_requirements */
#retouching_requirements{background:#1E1E2D; padding:40px 0px 20px;}
.pcimg{text-align:center; padding:10px 0px;}
.pcimg img{padding:10px 0px;}
.list_box {padding:15px 20px ;}
.list_box ul li{padding:2px 0px 2px 28px; font-size:18px; color:#fff; font-weight:300; position:relative;}
.list_box ul li:before{content:""; background:url(/media/misc/check-icon.png) no-repeat top left; height:17px; width:17px; background-size:cover; position:absolute; left:0px; top:7px; color:#fff; font-size:18px;  font-family: 'FontAwesome';}


.medbtn{text-align:left; padding:20px 2px;}
.medbtn a{margin:0px;}
/* End-retouching_requirements */

/* faq_section */
#faq_section{background:#EEF0F8; padding:30px 0px;}
#faq_section .acodinfaqstin .card-body p{ font-size:16px; color:#000; font-weight:400; margin:0;}
#faq_section .acodinfaqstin{ width:100%; margin:0 auto;}
#faq_section .acodinfaqstin .accordion{margin-bottom:5px;}
#faq_section a:hover,a:focus{ color:#fff; text-decoration: none; outline: none;}
.card-body{padding:15px; background:#fff; border-radius:0px 0px 10px 10px; margin:0px 0px 10px;}
#faq_section .card{border: none; box-shadow: none; border-radius: 0; margin-bottom: 0; background:none; border-bottom:1px solid #f0f2f7; margin:15px 0px;}
#faq_section .card-header { margin:0px 0; padding: 0; border: none; border-radius: 0; background:none;}
#faq_section .card-header  button{overflow:hidden; text-align:left;  width:100%; padding:0px; background:#1E1E2D; font-size:18px; padding:10px 15px; font-weight:400; color:#fff;  position: relative; transition: all ease 0.5s ; text-decoration:none; border:none ; border-radius:10px 10px 0px 0px;}
#faq_section .card-header  button.collapsed{color:#fff;  border:none;  background:#1E1E2D; border-radius:10px; }
/* #faq_section .card-header  button:before, #faqpage .card-header  button.collapsed:before{content:"\f106"; font-family:'FontAwesome'; font-weight:900; width:40px; height:46px; line-height:50px; background:none;  position:absolute; top:0px; right:0; font-size:17px; color:#fff; text-align:center; transition:all 0.5s ease 0s;} */
#faq_section .card-header  button:before, #faqpage .card-header  button.collapsed:before{content:"\f106"; font-family:'FontAwesome'; font-weight:900; width:40px; height:46px; line-height:50px; background:none;  position:absolute; top:17px; right:13px; font-size:17px; color:#fff; text-align:center; transition:all 0.5s ease 0s; background:url(/media/misc/up.png) no-repeat top center; background-size:cover;  height:13px; width:18px; content:"";}



/* #faq_section .card-header  button.collapsed:before{content:"\f107";} */
#faq_section .card-header  button.collapsed:before{ background:url(/media/misc/down.png) no-repeat top center; background-size:cover; height:13px; width:18px}



#faq_section .panel-body{border: solid 1px #ddd !important; font-size:13px; color:#888; line-height:25px; padding:10px 20px; position:relative; border:none; transition:all 0.5s ease 0s;}
.faq_boxs p{font-size:16px; font-weight:300; padding:0px 0px;}
/* End-faq_section */

/* form_section */
#form_section{background:#1E1E2D; padding:40px 0px;}
.contact_text h3{font-size:14px; font-weight:300; text-transform:uppercase; padding:2px 0px; color:#FFF}
.contact_text h1{font-size:40px; font-weight:700; padding:5px 0px; color:#FFF}
.contact_text h5{font-size:18px; font-weight:300;   color:#FFF; padding:15px 0px; line-height:28px}
.contact_text ul li{padding:2px 0px 2px 28px; font-size:18px; color:#fff; font-weight:300; position:relative;}
.contact_text ul li:before{content:""; background:url(/media/misc/check-icon.png) no-repeat top left; height:17px; width:17px; background-size:cover; position:absolute; left:0px; top:3px; color:#fff; font-size:18px;  font-family: 'FontAwesome';}


.form_box{background:#fff; border-radius:10px; padding:15px 25px; width:80%; display:block; margin:0px auto}
.form_box label{font-size:17px; font-weight:300; color:#1E1E2D; margin:0px; padding:8px 0px;}
.form_box .form-control{box-shadow:none; outline:none; border: 1px solid #1E1E2D; border-radius:3px; font-size:15px; font-weight:300; font-style:italic; height:42px;}
.form_box .btn{background:#1E1E2D; border:1px solid #FFFFFF; border-radius:3px; width:177px; color:#fff; padding:12px 0px; text-align:center; margin:10px 0px;}
.form_box .form-group{margin:0px; padding:5px 0px;}
/* End-form_section */

/* pricing_image */
#pricing_image{background:#EEF0F8; padding:30px 0px;}
.pricingimg{text-align:center; position:relative; padding:10px 0px;}
.withpix{position:absolute; left:0px; top:0px;}
.btn_bot{padding:10px 0px 0px;}
.btn_bot img{max-width:30px; margin:-5px 0px 0px}
/* End-pricing_image */

#footer{background:#1E1E2D; padding:20px 0px;}
#footer .row{align-items:center}
#footer p{font-size:16px; font-weight:300; color:#fff; text-align:right}
.logo img{max-width:150px;} 
  

.navbar{padding:0px 0px !important} 




@media (min-width: 1200px)
{
	section .container{max-wdith:1170px !important; width: 1170px; padding:0px 15px}
	.brand-dark .container{max-wdith:1170px !important;width: 1170px; padding:0px 15px}
}
@media (min-width: 992px) and (max-width: 1199px) {
	

/* top_banner */
#top_banner h1{font-size:50px;}
.vodeli video{height:245px}
#top_banner p{font-size:16px;}
#top_banner h3{font-size:16px;}
/* End-top_banner */

.head h1{font-size:32px;}

/* retouching_section */
.main_videosi{width:650px;}
/* End-retouching_section */

.achie_box{padding:20px 10px;}
.achie_box h1{font-size:47px;}
.achie_box p{font-size:16px;}

.form_box{width:100%}
}

@media (min-width: 768px) and (max-width: 991px) {

/* top_menu */
.top_menu .navbar-light .navbar-nav .nav-item{padding:4px 0px}
/* End-top_menu */

/* top_banner */
#top_banner h1{font-size:50px;}
.vodeli video{height:245px}
#top_banner p{font-size:16px;}
#top_banner h3{font-size:16px;}
.vodeli{padding:10px 0px 0px;}
/* End-top_banner */

.head h1{font-size:32px;}

/* retouching_section */
.main_videosi{width:95%;}
.with img{max-width:100px;}
.without img{max-width:100px;}
/* End-retouching_section */

/* retouching_services */
.be_afbox{padding:10px 0px;}
.be_afbox img{width:100%}
.editafter{width:auto; margin-left:-15px; margin-right:-15px;}

/* End-retouching_services */

/* retouching_requirements */
.list_box{padding:0px 0px;}
/* End-retouching_requirements */

/* reviews_section */
.slider_box{width:100%}
/* End-reviews_section */


.achie_box{padding:20px 10px;}
.achie_box h1{font-size:47px;}
.achie_box p{font-size:16px;}

.form_box{width:100%; margin:15px 0px 0px}
.withpix {left:-25px;}
.withpix img{max-width:100px;}

}
@media (max-width: 767px) {

/* top_menu */
.top_menu .navbar-light .navbar-nav .nav-item{padding:4px 0px}
/* End-top_menu */

/* top_banner */
#top_banner h4{padding:25px 0px;}
#top_banner h1{font-size:30px;}
#top_banner a{width:100%}
.vodeli video{height:245px}
#top_banner p{font-size:16px;}
#top_banner h3{font-size:16px;}
.vodeli{padding:10px 0px 0px;}
#top_banner h4{padding:10px 0px; text-align:center}
/* End-top_banner */


.head h1{font-size:25px;}
.head p{font-size:15px;}

/* retouching_section */
.main_videosi{width:100%; margin:0px auto 15px;}
.with {display:none}
.without {display:none}
.bimg{padding:2px}
.timg{padding:2px}
#retouching_section .owl-item.active.current .bimg img{border-width:2px;}
#retouching_section .owl-item .bimg img{border-width:2px;}
.butonn_tenphoto a{width:100%; font-size:15px;}
/* End-retouching_section */

/* retouching_services */
.be_afbox{padding:10px 0px;}
.be_afbox img{width:100%}
.editafter{width:auto; margin-left:-15px; margin-right:-15px;}
.be_afbox p{font-size:15px;}
.four_box p{font-size:15px;}
.addmore a{padding:6px 10px; font-size:14px;}
.addmore{padding:10px 0px}
/* End-retouching_services */

/* retouching_requirements */
.list_box{padding:0px 0px;}
/* End-retouching_requirements */


/* achievements_section */
.achie_box{padding:20px 10px;}
.achie_box h1{font-size:32px;}
.achie_box p{font-size:16px;}
/* End-achievements_section */

/* reviews_section */
.slider_box{width:100%}
.clint_rew p{font-size:14px;}
/* End-reviews_section */

/* retouching_requirements */
.list_box ul li{font-size:16px;}
#retouching_requirements{padding:30px 0px 20px;}
/* End-retouching_requirements */

/* faq_section */
#faq_section .card-header button{font-size:16px; padding-right:27px;}
#faq_section .acodinfaqstin .card-body p{font-size:14px;}
/* End-faq_section */

/* form_section */
.contact_text h1{font-size:25px;}
.form_box{width:100%; margin:15px 0px 0px; padding:10px 10px;}
.contact_text h5{font-size:15px; padding:5px 0px; line-height:inherit}
.contact_text ul li{font-size:15px;}
.contact_text ul{padding:8px 0px;}
.form_box label{font-size:16px;}
.form_box .form-control{font-size:14px;}
/* End-form_section */

.withpix {left:-5px; top:-20px}
.withpix img{max-width:50px;}

#footer{text-align:center; padding:13px 0px;}
#footer p{text-align:center; padding:7px 0px;}

}


@media (max-width: 480px) {

.vodeli video{height:196px;}
}








*{
    scroll-behavior: smooth;
}

.addmore span{border:1px solid #1E1E2D; color:#1E1E2D; padding:6px 29px; border-radius:3px; text-decoration:none; display:inline-block; margin:5px; font-size:16px;}



.top_menu .navbar-light .navbar-nav .nav-item:last-child .nav-link{background:#1e1e2d; color:#fff}
