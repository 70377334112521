.socilgi{display:flex; flex-wrap:wrap}
.socilgi li{max-width:50%; padding:1px 3px; flex:50%}
.btn.btn-facebook{display:block; padding:15px 10px; width:100%}
.max-h-100px.loginimge{width:100%; max-height:inherit !important}
.socilgi.dlidersi{padding:10px 0px}

.orword{text-align: center; font-weight:400; font-size:28px; color:#B5B5C3 ;}
.socilgi.dlidersi { padding: 10px 0px; width: 518px;  margin: 0px 0px 0px -43px;}
.socilgi li{padding:11px 15px;}
.socilgi{width: 518px;  margin: 0px 0px 0px -43px;}
.checkbox > span{position: absolute; left: 0px;}
.checkbox a{padding-left:26px;}

@media (min-width: 768px) and (max-width: 991px) {
.socilgi.dlidersi {margin:0px auto; width:100%}
.socilgi{margin:0px auto; width:100%}
	}
	@media (max-width: 767px) {
.socilgi.dlidersi {margin:0px auto; width:100%}
.socilgi{margin:0px auto; width:100%}
	}


	.terms-conditions{
		color:#3699FF !important;
	  }