.dashboard-modal{
    width:80vw !important;
    height: 100vh !important;
    max-width: 80vw;
    max-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-content{
    justify-self: center;
    align-self: center;
    height: 90%;
    width: auto !important;
  }

  .card-text {
    display: block;
 text-align: left;
    padding: 10px 0px;
}


.p_rt{display:flex;flex-wrap:wrap; align-items:center}
.p_rt .col8{max-width:55%; flex:55%; padding:0px 15px;}
.p_rt .col4{max-width:45%; flex:45%;  padding:0px 15px;}
.p_rt h2{font-size:2em; font-weight:700; padding:5px 0px;}
.p_rt p{font-size:1em; font-weight:400; padding:5px 0px;}
.p_rt .col4 img{max-width:100%}
.card.card-custom > .card-header.mider{padding-right: 0px; display: block;}
.gutter-b.paddrig{padding-right:0px; }
.card.card-custom > .card-header .card-toolbar.buttonree{position: absolute;
    top: 38px;
    right: 0px;}

@media (max-width: 767px) {
.p_rt .col8{max-width:100%; flex:100%; padding:5px 0px}
.p_rt .col4{max-width:100%; flex:100%;  padding:5px 0px}
}


.quickvideocard >div{
  width: 200px;
  height: 150px;
  justify-items: center;
  display: grid;
}
.dt_box{display:flex; flex-wrap:wrap; align-items: center;}
.dt_box .col6{max-width:45%; flex:45%; padding:10px 45px}
.dt_box .col1{max-width:10%; flex:10%; padding:10px 10px}
.contain{width:234px;}
.display-4.markte{margin-top:20px; font-size:2.1em !important;}
.detl{font-size:1.1em !important; padding:5px 0px !important}
.dlierss{background: #f7f7f7 !important;
    padding: 13px 21px;
    font-size: 17px;
    margin: 22px auto 0px;
    border:none !important;
}

.btn.btn-light-primary {
    color: #3699FF;
    background-color: #E1F0FF;
    border-color: transparent;
    padding: 10px 14px;
    font-size: 14px;
    margin: 0px 0px 13px;
    width: 96px;
}

@media (max-width: 767px) {
  .dt_box .col6{max-width:100%; flex:100%; padding:10px 10px}
.dt_box .col1{max-width:100%; flex:100%; padding:10px 10px}}

iframe.ytp-large-play-button{width:38px !important;}